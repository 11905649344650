<template>
  <div id="detail-page-main">
    <div class="main-layout" v-if="dataFetched" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
      <div class="content-container" id="detail-page-main">
        <div class="content-detail">
          <!-- <div class="go-back" v-if="checkScreenWidth" @click="goBack()">
            <img
              src="@/assets/icons/back.svg"
              v-if="localDisplayLang === 'eng'"
              alt
            />
            <img src="@/assets/icons/back.svg" class="rotate-img" v-else alt />
            <p class="back">{{ $t("Back") }}</p>
          </div> -->
          <div>
            <div
              :class="[localDisplayLang == 'ara' ? 'contentTags' : 'contentTagsRight']"
              v-if="isObjectTag(contentDetails)"
            >
              <p class="tag">{{ pickTagForContent(contentDetails) }}</p>
            </div>
          </div>
          <h1 class="content-title">{{ contentDetails.title }}</h1>

          <div class="content-sub-detail">
            <p v-if= "contentDetails.category" class="active-pointer"  @click="actRedirectionMetaTag('category', contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype)">{{ $t(`${contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype}`) }}</p>

            <p v-if="contentDetails.genre">|</p>

            <p v-if="contentDetails.genre" class="active-pointer" @click="actRedirectionMetaTag('genre',contentDetails.defaultgenre)">{{ contentDetails.genre }}</p>

            <p v-if="contentDetails.contentlanguage[0]">|</p>

            <p v-if="contentDetails.contentlanguage[0]" class="active-pointer" @click="actRedirectionMetaTag('language', contentDetails.contentlanguage[0])">
              {{ contentDetails.contentlanguage[0] ? $t(`${contentDetails.contentlanguage[0]}`) : "" }}
             </p>

            <p v-if="contentDetails.productionyear">|</p>

            <p v-if="contentDetails.productionyear" class="active-pointer" @click="actRedirectionMetaTag('productionyear', contentDetails.productionyear)">{{ contentDetails.productionyear }}</p>

            <p v-if="contentDetails.pgrating">|</p>

            <p dir="ltr" class="pg-rating" v-if="contentDetails.pgrating">{{ contentDetails.pgrating }}</p>
          </div>
          <div class="season-layout" v-if="contentDetails.seasoncount">
            <img src="@/assets/icons/season-light.svg" alt="items-count" style="margin-top:15px" />
            <p class="season-info">
              {{
                contentDetails.seasoncount > 1
                  ? contentDetails.seasoncount + " " + $t("Seasons")
                  : contentDetails.seasoncount + " " + $t("Season")
              }}
            </p>
          </div>
          <div
            class="content-description"
            :class="{ 'content-description-ara': localDisplayLang === 'ara' }"
            v-if="checkDescription"
          >
            <p v-if="!readMore">
              {{ getDescription }}
            </p>
            <p v-else>
              {{ getDescriptionReadMore }}
            </p>
          </div>
          <div v-if="checkDescription && checkDescriptionLength">
            <span v-if="!readMore" class="read-more" id="readMore" @click="manageReadMore()">
              {{ $t("read more") }}</span
            >
            <span v-else class="read-more" id="readMore" @click="manageReadLess()">{{ $t("read less") }}</span>
          </div>

          <!-- Audio and Subtitle Language Display -->
          <div v-if="getAudioAndSubtitleLangState" class="audio-subtitle">
            <div
              v-if="
                getAudioAndSubtitleLangState &&
                  getAudioAndSubtitleLangState.audiolang &&
                  getAudioAndSubtitleLangState.audiolang.length
              "
              class="audio"
            >
              <span class="label"> {{ $t("Audio") }} </span>:
              <span v-for="(lang, index) in getAudioAndSubtitleLangState.audiolang" :key="index">
                {{ $t(getLanguageFullForm(lang))
                }}<span v-if="index < getAudioAndSubtitleLangState.audiolang.length - 1">,</span>
              </span>
            </div>
            <div
              v-if="
                getAudioAndSubtitleLangState &&
                  getAudioAndSubtitleLangState.subtitlelang &&
                  getAudioAndSubtitleLangState.subtitlelang.length
              "
              class="subtitle"
            >
              <span class="label"> {{ $t("Subtitle") }} </span>:
              <span v-for="(lang, index) in getAudioAndSubtitleLangState.subtitlelang" :key="index">
                {{ $t(getLanguageFullForm(lang))
                }}<span v-if="index < getAudioAndSubtitleLangState.subtitlelang.length - 1">,</span>
              </span>
            </div>
          </div>

          <!-- Quick actions -->
          <div class="content-quick-actions">
            <LikeBtn :content="contentDetails" :width="'20px'" :height="'20px'" class="hover-change"></LikeBtn>
            <DislikeBtn :content="contentDetails" :width="'20px'" :height="'20px'" class="hover-change"></DislikeBtn>
            <detailShare :content="contentDetails" :width="'20px'" :height="'20px'" class="hover-change"></detailShare>
            <WatchListBtn
              :content="contentDetails"
              :width="'20px'"
              :height="'20px'"
              class="hover-change"
            ></WatchListBtn>
          </div>
          <div class="content-actions">
            <button class="play-button" v-if="checkMovie" @click="buttonHandler">
             <span class="btn-txt-color">{{ $t(buttonStatus) }} </span>
            </button>
            <button class="watch-trailer-button" @click="watchTrailer()" v-if="contentDetails.trailer">
              {{ $t("WATCH TRAILER") }}
            </button>
          </div>
        </div>
        <div class="content-image">
          <img :src="getPoster()" class="poster" alt="Loading...." />
        </div>
      </div>
      <div class="tabs-section">
        <div class="tabs-layout">
          <div>
            <ul class="tabs-frame">
              <li
                v-for="(item, index) in tabList"
                :key="index"
                @click="selectTab(index, item)"
                :class="selectedSection === index ? activeTab : ''"
              >
                {{ $t(item) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="episodes-layout">
          <!-- <component :is="activeComponent" :contentDetails="contentDetails">
          </component> -->
          <keep-alive>
            <router-view :contentDetails="contentDetails" :playerInstance="playerInstance"></router-view>
          </keep-alive>
        </div>
      </div>
      <!-- <p v-if="!contentDetails" style="color:white">Show loader</p> -->
    </div>
    <div v-if="!dataFetched">
      <detailPageLoader></detailPageLoader>
    </div>
    <div class="errorMessage" v-if="showErrorMessage">
      <p>No contents found</p>
    </div>
  </div>
</template>

<script>
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { _projectName } from "@/provider-config.js";
import { store } from "@/store/store";

import {showSnackBar} from '@/utilities';

export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      dataFetched: false,
      localDisplayLang: null,
      contentDetails: null,
      contentId: null,
      readMore: false,
      tabList: [],
      selectedSection: null,
      activeTab: "active-tab",
      activeComponent: "",
      showErrorMessage: false,
      seasonNum: 1,
      seasonCount: null,
      seasonCountArray: [],
      subscriptions: [],
      availabilities: [],
      purchases: [],
      filteredAvailabilities: [],
      pricemodel: [],
      buttonStatus: null,
      fetchStreamUrlTask: null,
      playbackInitiated: false,
      isMpegRequired: false,

      testLangArr: ["Spanish", "English", "Hindi"],
    };
  },
  watch: {
    $route(to, from) {
      console.log("Route to : ", to);
      console.log("Route from : ", from);
      this.selectTabUI();
      if (to.name == "detailPage" && from.name == "related") {
        eventBus.$emit("callAvailability");
      }
      if (
        (to.name == "detailPage" && from.name == "episodes") ||
        (to.name == "detailPage" && from.name == "trailer") ||
        (to.name == "detailPage" && from.name == "movieTrailer") ||
        (to.name == "detailPage" && from.name == "related") ||
        (to.name == "detailPage" && from.name == "relatedMovie")
      ) {
        location.reload();
      } else if (
        (to.name == "detailPageMovie" && from.name == "episodes") ||
        (to.name == "detailPageMovie" && from.name == "trailer") ||
        (to.name == "detailPageMovie" && from.name == "related") ||
        (to.name == "detailPageMovie" && from.name == "movieTrailer") ||
        (to.name == "detailPageMovie" && from.name == "relatedMovie")
      ) {
        location.reload();
      } else if (
        (to.name == "detailPage" && from.name == "related") ||
        (to.name == "detailPageMovie" && from.name == "relatedMovie")
      ) {
        this.selectedSection = null;
        this.tabList = [];
        this.contentDetails = null;
        console.log("this.contentdetails are----------", this.contentDetails);
        this.contentId = to.params.contentId;
        this.getContentDetail(this.contentId);
        location.reload();
      }
    },
    availabilityList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.availabilities = val;
        this.getAvailability(this.contentDetails);
      }
    },
    subscriptionList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.subscriptions = val;
        this.getAvailability(this.contentDetails);
      }
    },
  },
  created() {
    // this.contentDetails = this.$route.params.content
    //console.log("route params are----", this.$route.params);
    //console.log("created id is---", this.contentId);
    this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

    if (subscriberProfileDetails) {
      subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
    }

    this.contentId = this.$route.params.contentId;
    this.getContentDetail(this.contentId);
    this.scrollTop();


    eventBus.$on(`contentDetail-response-${this.contentId}`, (response) => {
      console.log("what is the response------------------from createdd--------------", response);
      if (!response.reason) {
        this.dataFetched = true;
        this.contentDetails = response.data;
				this.getAvailability(this.contentDetails);

        window.document.title = response.data.title + "-" + 'Noor Play';
      document.getElementById("meta-desc").setAttribute(
        "content",
        response.data.shortdescription || 'At Noorplay we care about conservative content and family values. Every content is carefully picked and edited to suit all family members.Noor Play is a streaming service that offers a wide variety of TV shows and more'
      );
   
        this.seasonNum = this.contentDetails.seasoncount;
        this.fetchTabList();
        this.selectTab(0);

        if (
          subscriberProfileDetails &&
          subscriberProfileDetails.kidsmode === "YES" &&
          this.contentDetails.defaultgenre !== "KIDS"
        ) {
          showSnackBar(this.$t("Content is not accessible"));
          this.$router.replace("/");
        } 
      } else if (reason.response) {
        this.dataFetched = true;
        this.showErrorMessage = true;
      }
    });
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    console.log("THE LOG CHECK --------");

    setTimeout(() => {
      //console.log("THE NEWWW LOGGGG ----- ", this.contentDetails, this.contentDetails.category !== "TVSHOW");
      if (this.contentDetails && this.contentDetails.category !== "TVSHOW") {
        // this.fetchButtonStatus();
        console.log("THE TIMER CHECK --- ");
        this.getAvailability(this.contentDetails);
      }
    }, 3000);
  },

  computed: {
    ...mapGetters([
      "appConfig",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "getAudioAndSubtitleLangState",
      "getAllLanguageFullForm",
      "appConfig",
      "profileid",
    ]),

    checkScreenWidth: function() {
      if (screen.width > 900) {
        return true;
      } else {
        return false;
      }
    },

    contentTags() {
      return this.contentDetails.tags[0].toLowerCase();
    },

    seasons: function() {
      if (this.contentDetails && this.contentDetails.seasoncount && this.contentDetails.seasoncount > 1) {
        return this.contentDetails.seasoncount + " " + "Seasons";
      } else if (this.contentDetails && this.contentDetails.seasoncount && this.contentDetails.seasoncount <= 1) {
        return this.contentDetails.seasoncount + " " + "Season";
      } else {
        return "";
      }
    },

    checkDescription: function() {
      if (
        (this.contentDetails && this.contentDetails.longdescription) ||
        (this.contentDetails && this.contentDetails.shortdescription)
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkDescriptionLength: function() {
      if (
        (this.contentDetails &&
          this.contentDetails.longdescription &&
          this.contentDetails.longdescription.length > 250) ||
        (this.contentDetails &&
          this.contentDetails.shortdescription &&
          this.contentDetails.shortdescription.length > 250)
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkMovie: function() {
      if (this.contentDetails.category == "TVSHOW") {
        return false;
      } else {
        return true;
      }
    },

    getDescription() {
      if (this.contentDetails.shortdescription && this.contentDetails.shortdescription.length > 250) {
        return this.contentDetails.shortdescription.slice(0, 250);
      } else if (this.contentDetails.longdescription && this.contentDetails.longdescription.length > 250) {
        return this.contentDetails.longdescription.slice(0, 250);
      } else if (this.contentDetails.shortdescription) {
        return this.contentDetails.shortdescription;
      } else if (this.contentDetails.longdescription) {
        return this.contentDetails.longdescription;
      }
    },

    getDescriptionReadMore() {
      if (this.contentDetails.longdescription) {
        return this.contentDetails.longdescription;
      } else if (this.contentDetails.shortdescription) {
        return this.contentDetails.shortdescription;
      }
    },
  },
  mounted() {
    this.setAudioAndSubtitleLangState(null);

    eventBus.$on("changeLanguageView", () => {
      location.reload();
      //    this.contentId = this.$route.params.contentId;
      // this.getContentDetail(this.contentId);
    });

    setTimeout(() => {
      if (this.$route.fullPath.includes("play") && this.$route.fullPath.includes("trailer")) {
        this.watchTrailer();
      } else if (this.$route.fullPath.includes("play")) {
        if (this.subscriberid) {
          this.buttonHandler("movie");
        } else {
          let payload = {
            state: true,
            formType: "lookup",
          };

          eventBus.$emit("authPopup", payload);
        }
      }
    }, 2000);

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
      }
    });
    // eventBus.$on("startLoader", ()=>{
    //   console.log('START LOADER ON EPISODE EMIT')
    //   // this.dataFetched = true
    // });
    // eventBus.$on("endLoader",()=>{
    //   console.log('END LOADER ON EPISODDE EMIT')
    //   // this.dataFetched = false
    // })
  },
  components: {
    detailPageLoader: () =>
      import(/* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"),
    episodeCard: () => import(/* webpackChunkName: "episodeCard" */ "@/components/DetailPage/episodeCard.vue"),
    trailerCard: () => import(/* webpackChunkName: "trailerCard" */ "@/components/DetailPage/trailerCard.vue"),
    relatedCard: () => import(/* webpackChunkName: "relatedCard" */ "@/components/DetailPage/relatedCard.vue"),
    LikeBtn: () => import(/* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"),
    DislikeBtn: () => import(/* webpackChunkName: "dislikebutton" */ "@/components/SvgImages/DislikeBtn.vue"),
    WatchListBtn: () => import(/* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"),
    detailShare: () => import(/* webpackChunkName: "detailshare" */ "@/components/Templates/detailShare.vue"),
  },
  methods: {
    ...mapMutations(["setRegActiveDeeplink", "setAudioAndSubtitleLangState"]),

    ...mapActions(["getLanguagesByCodes", "authorizeCurrentStreamApi", "setCurrentStreamApi"]),

    actRedirectionMetaTag(type, tag){
     let section = (tag.toString()).toLowerCase()
     let currentLanguage = this.getCurrentLanguageUrlBase();
     this.$router.push({ name: "screen-meta-redirection", params: { objecttype: (this.contentDetails.objecttype).toLowerCase(), value: section , type: type, lang: currentLanguage } });

    },
    getLanguageFullForm(lang) {
      let fullForm = "";
      if (lang) {
        this.getAllLanguageFullForm.forEach((langObj) => {
          if (langObj["alpha3-b"] === lang) {
            fullForm = langObj.English;
            return;
          }
        });
      }

      return fullForm;
    },

    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    buttonHandler(isDeeplink = false) {
      let fullPath = this.$route.fullPath.split("/");
      if (isDeeplink == "movie") {
        window.history.pushState("object or string", fullPath[fullPath.length - 1], "play");
      } else {
        window.history.pushState("object or string", fullPath[fullPath.length - 1], "play");
      }

      if (!this.subscriberid && (this.buttonStatus === "SUBSCRIBE & WATCH" || this.buttonStatus === "LOGIN & WATCH")) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (
        this.subscriberid &&
        this.buttonStatus === "SUBSCRIBE & WATCH" &&
        this.subscriberid !== this.profileid
      ) {
        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
          },
        });
      }

      // For master Profile
      else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH") {
        console.log("THIS IS THE SUBSCRIBE POPUP");
        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        let payload = { listType: "onlySubscribe" };
        eventBus.$emit("subscribePopup", payload);
      } else if (this.subscriberid && this.buttonStatus === "PLAY NOW") {
        this.showPlayer();

        let contentList = [this.contentDetails];
        console.log("Content list : ", contentList);
        this.loadChosenIndexContent(0, contentList, contentList.length, true);

        // this.closePopup();
      }
    },
    loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          let loadPlayer = false;

          if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
            // Call the Stream Authorize API
            this.authorizeCurrentStreamApi().then((authResponse) => {
              console.log("authResponse - From Authorize API --- DETAILPAGE > ", authResponse);

              if (!authResponse.data.errorcode) {
                if (typeof resp == "object" && !resp.data.errorcode) {
                  this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                  loadPlayer = true;
                } else if (typeof resp == "boolean" && resp == true) {
                  loadPlayer = true;
                }
                if (loadPlayer == true) {
                  this.loadContentMetaData(episodeList[episodeIndex], null);

                  let playlist = this.buildPlayerPlaylist(episodeList, false, 0);
                  this.playerInstance.loadPlaylist(playlist, totalCount);

                  this.playerInstance.loadContent(episodeIndex).then(() => {
                    this.playerInstance.play().then(() => {
                      console.log("Playback successful");

                      const payload = {
                        contentId: episodeList[episodeIndex].objectid,
                        params: {
                          devicetype: "PC",
                        },
                      };

                      this.setCurrentStreamApi(payload).then((setStreamResponse) => {
                        if (setStreamResponse.data.errorcode) {
                          this.hidePlayer();

                          eventBus.$emit("showAuthorizeErrorMessage", {
                            state: true,
                            messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                          });
                        }
                      });
                    });
                  });
                } else {
                  let playbackError = true;

                  if (typeof resp == "object") {
                    playbackError = resp.data;
                  }

                  this.hidePlayer();

                  eventBus.$emit("showAuthorizeErrorMessage", {
                    state: true,
                    messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                  });

                  throw playbackError;
                }
              } else {
                let playbackError = true;

                if (typeof resp == "object") {
                  playbackError = resp.data;
                }

                this.hidePlayer();

                eventBus.$emit("showAuthorizeErrorMessage", {
                  state: true,
                  messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                });

                throw playbackError;
              }
            });
          }

          // Else if Concurrency is not enabled
          else {
            if (typeof resp == "object" && !resp.data.errorcode) {
              this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
              loadPlayer = true;
            } else if (typeof resp == "boolean" && resp == true) {
              loadPlayer = true;
            }
            if (loadPlayer == true) {
              this.loadContentMetaData(episodeList[episodeIndex], null);

              let playlist = this.buildPlayerPlaylist(episodeList, false, 0);
              this.playerInstance.loadPlaylist(playlist, totalCount);

              this.playerInstance.loadContent(episodeIndex).then(() => {
                this.playerInstance.play().then(() => {
                  console.log("Playback successful");
                });
              });
            } else {
              let playbackError = true;

              if (typeof resp == "object") {
                playbackError = resp.data;
              }

              this.hidePlayer();

              throw playbackError;
            }
          }
        })
        .catch((error) => {
          console.log("Content playback failed : ", error);
          this.hidePlayer();
        });
    },
    getAvailability(contentItem) {
      console.log("THE PACKAGE LIST --- CHECK", contentItem);

      if (contentItem && contentItem.hasOwnProperty("contentdetails") && contentItem.contentdetails.length > 0) {
        this.isButtonEnable = true;
        let packageDetails = this.getFilteredPackage(contentItem);

        const langPayload = { audiolang: packageDetails.audiolang, subtitlelang: packageDetails.subtitlelang };

        this.setAudioAndSubtitleLangState(langPayload);

        console.log("THE PACKAGE LIST --- ", packageDetails);

        console.log("THE PACKAGE LIST GETTER --- ", this.getAudioAndSubtitleLangState);
        let availPlanList = this.getAvailabilityAndPlanDetails(packageDetails, this.availabilities);
        let pricemodel = availPlanList.pricemodel[0];
        //based on pricemodel of availabilities assigning buttonstatus.
        if (pricemodel.includes("PLAN")) {
          this.buttonStatus = "SUBSCRIBE & WATCH";
        } else if (pricemodel.includes("FREE")) {
          this.buttonStatus = "LOGIN & WATCH";
        }

        let filteredAvailabilityList = availPlanList.filteredAvailabilities;

        console.log("filtered availabilities", filteredAvailabilityList);

        filteredAvailabilityList.every((availability) => {
          if (availability.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.every((subscription) => {
                // Check if next billing date is greater than today's date.
                if (this.getDateForPlayback(subscription.nextbilling) < this.getTodaysDate()) return;

                let value = subscription.availabilityset.includes(availability.availabilityid);

                if (value) {
                  contentItem.isDrmContent = true;
                  this.isButtonEnable = true;
                  this.buttonStatus = "PLAY NOW";
                  contentItem.videoType = "Content";
                  contentItem.playback_details = {
                    packageid: packageDetails.packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageDetails.drmscheme[0],
                  };

                  return false;
                }
              });
            } else if (this.subscriberid && this.subscriptions.length === 0) {
              console.log(
                "content price model is not free and user has to subscribe to the plan to watch this content"
              );
            }
          } else if (availability.pricemodel == "FREE") {
            contentItem.isDrmContent = true;
            this.isButtonEnable = true;
            this.buttonStatus = "PLAY NOW";
            contentItem.videoType = "Content";
            //creating this playback_details for content playback.
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageDetails.drmscheme[0],
            };
            return false;
          }
        });
      }
    },
    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      let priceAndAvailabilityData = {};
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];

      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData.pricemodel.push(availabilitiesList[availabilityIndex].pricemodel);
          priceAndAvailabilityData.filteredAvailabilities.push(availabilitiesList[availabilityIndex]);
        }
      });

      return priceAndAvailabilityData;
    },
    getFilteredPackage(contentItem) {
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      let packageList = [];

      //pick content details if that availability id is there in availability list.
      this.availabilities.forEach((availability) => {
        contentItem.contentdetails.forEach((element) => {
          if (element.availabilityset.includes(availability.availabilityid)) {
            packageList.push(element);
          }
        });
      });

      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return contentPackage.drmscheme[0] === "FAIRPLAY";
        } else {
          return contentPackage.drmscheme[0] === "WIDEVINE";
        }
      });

      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            return packageList.drmscheme[0] === "NONE";
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },
    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload = {
        contentid: contentItem.objectid,
        params: {
          availabilityid: contentItem.playback_details.availabilityid,
          packageid: contentItem.playback_details.packageid,
        },
      };

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      return payload;
    },

    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        console.log("Fetch content stream : ", contentItem.streamUrl);
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          console.log("Fetch stream resolved");
          resolve(true);
        } else {
          console.log("Fetching stream");
          resolve(store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem, isMpegRequired)));
        }
      });
    },
    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
			contentItem.scrubbing =response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : "";
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    goBack() {
      console.log("go back is called---------------");
      this.$router.push({ path: "/" });
    },
    getContentDetail(contentid) {
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };

      if (this.checkMpegRequired() === true) {
        payload.params.mpegtspackage = "YES";
      }

        store
      .dispatch("contentDetail", payload)
      .then((response) => {
        if (!response.data.reason) {
          eventBus.$emit(`contentDetail-response-${payload.contentid}`, response);
        } else {
          eventBus.$emit(`contentDetail-error-response-${payload.contentid}`, response);
        }
      })
      .catch((error) => console.log(error));

      // eventBus.$emit("getContentDetail", payload);
    },
    getLatestSeason() {
      for (let i = 1; i <= this.seasonCount; i++) {
        console.log("i--------------------", i);
        this.seasonCountArray.push(i);
      }
      this.seasonNum = this.seasonCountArray.length;
    },
    getPoster() {
      let content = this.contentDetails;
      if (content && content.poster && content.poster.length && !content.inwatchlist) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      }
    },
    manageReadMore() {
      this.readMore = true;
    },
    manageReadLess() {
      this.readMore = false;
    },
    fetchTabList() {
      if (
        this.contentDetails.category === "MOVIE" &&
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.tabList = ["Trailer", "Related"];
      } else if (this.contentDetails.category === "MOVIE") {
        this.tabList = ["Related"];
      } else if (
        this.contentDetails.category === "TVSHOW" &&
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0 &&
        this.contentDetails.tags[0].toLowerCase() != "upcoming"
      ) {
        console.log("coming to tv show 1");
        this.tabList = ["Episodes", "Trailer", "Related"];
      } else if (
        this.contentDetails.category === "TVSHOW" &&
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.tabList = ["Episodes", "Trailer", "Related"];

        console.log("coming to tv show 2 --- > ", this.contentDetails.tags);
        if (this.contentDetails.tags[0].toLowerCase() === "upcoming") {
          console.log("updated tab list----------", this.tabList);
          this.tabList.splice(0, 1);
        }
      } else if (
        this.contentDetails.category === "TVSHOW" &&
        !this.contentDetails.trailer &&
        this.contentDetails.tags[0].toLowerCase() === "upcoming"
      ) {
        this.tabList = ["Related"];
      } else if (this.contentDetails.category === "TVSHOW" && !this.contentDetails.trailer) {
        this.tabList = ["Episodes", "Related"];
      }
    },
    selectTab(index, item) {
      console.log("this.selectTab==================", this.tabList);
      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.selectTabUI();
      this.selectDefaultTab(index);
      if (item) {
        if (this.contentDetails.category == "TVSHOW") {
          if (item == "Episodes") {
            this.selectedSection = index;
            this.$router.push({
              name: "episodes",
              params: { id: this.seasonNum, lang: currentLanguage  },
            });
          } else if (item == "Trailer") {
            this.selectedSection = index;
            this.$router.push({ name: "trailer", params: {lang: currentLanguage } });
          } else if (item == "Related") {
            this.selectedSection = index;
            this.$router.push({ name: "related", params: {lang: currentLanguage } });
          }
        } else {
          if (item == "Trailer") {
            this.selectedSection = index;
            this.$router.push({ name: "movieTrailer", params: {lang: currentLanguage } });
          } else if (item == "Related") {
            this.selectedSection = index;
            this.$router.push({ name: "relatedMovie", params: {lang: currentLanguage } });
          }
        }
      }
    },

    selectTabUI() {
      let fullPath = this.$route.fullPath.split("/");
      let selectedSectionPath = fullPath[fullPath.length - 1];
      var sectionLowercase = this.tabList.map((v) => v.toLowerCase());
      let sectionIndex = sectionLowercase.indexOf(selectedSectionPath);
      if (sectionIndex == -1) {
        this.selectedSection = 0;
      } else {
        this.selectedSection = sectionIndex;
      }
    },
    selectDefaultTab(index) {

     let currentLanguage = this.getCurrentLanguageUrlBase();

      if (this.contentDetails.category == "TVSHOW") {
        if (this.$router.currentRoute.name == "detailPage" && this.contentDetails.tags[0].toLowerCase() != "upcoming") {
          this.selectedSection = index;
          this.$router.replace({
            name: "episodes",
            params: { id: this.seasonNum, lang: currentLanguage },
          });
        } else if (
          this.$router.currentRoute.name == "detailPage" &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming" &&
          this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({ name: "trailer", params: {lang: currentLanguage } });
        } else if (
          this.$router.currentRoute.name == "detailPage" &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming" &&
          !this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({ name: "related", params: {lang: currentLanguage } });
        }
        // else {
        //   this.selectedSection = index;
        //   this.$router.push({ name: "related" });
        // }
      } else {
        if (
          this.$router.currentRoute.name == "detailPageMovie" &&
          this.contentDetails.category == "MOVIE" &&
          this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({ name: "movieTrailer",  params: { lang: currentLanguage } });
        } else if (
          this.$router.currentRoute.name == "detailPageMovie" &&
          this.contentDetails.category == "MOVIE" &&
          !this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({ name: "relatedMovie",  params: { lang: currentLanguage }  });
        } else if (
          this.$router.currentRoute.name == "movieTrailer" &&
          this.contentDetails.category == "MOVIE" &&
          this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({ name: "movieTrailer",  params: { lang: currentLanguage }  });
        }  else {
          this.selectedSection = index;
          this.$router.replace({ name: "relatedMovie",  params: { lang: currentLanguage }  });
        }
      }
    },
    watchTrailer() {
      //To aviod drm check.
      if (this.contentDetails.trailer && this.contentDetails.trailer.length > 0) {
        this.showPlayer();
        this.loadContentMetaData(this.contentDetails, null);
        this.contentDetails.isDrmContent = false;
        this.contentDetails.trailerUrl = this.contentDetails.trailer[0].filelist[0].filename;

        let locationArr = location.pathname.split("/");

        window.history.pushState(
          "object or string",
          {},
          "/" +
            locationArr[1] +
            "/" +
            locationArr[2] +
            "/" +
            locationArr[3] +
            "/trailer/" +
            this.contentDetails.trailer[0].trailerid +
            "/play"
        );

        let contentList = [this.contentDetails];
        let playlist = this.buildPlayerPlaylist(contentList, true, 0);
        this.playerInstance.loadPlaylist(playlist, contentList.length);
        console.log(playlist);
        this.playerInstance.loadContent(0).then(() => {
          this.playerInstance.play().then(() => {
            console.log("Playback successful in detail page");
          });
        });
      } else {
        this.$router.push({ name: "related" });
      }
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], isTrailer, 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },
    loadNextContentData(endedContentIndex) {
      this.hidePlayer();
    },
    hidePlayer() {
      this.playerInstance.shrinkPlayer();

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },
    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid != undefined && content.objectid != "" ? content.objectid : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
          ? [content.streamUrl]
          : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: "noorplay",
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: isTrailer ? false : content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: contentHead,
				scrubbing: content.scrubbing ? content.scrubbing[0] : "",
      });

      return playlistItem;
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
    actChangeMetaData() {
      let _localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

      if (_localDisplayLang === "ara") {
        document
          .getElementById("meta-desc")
          .setAttribute(
            "content",
            "نوربلاي هو موقع  يقدم خدمة المشاهدة اونلاين لمجموعة متنوعة من المسلسلات العربية ، التركية، الأجنبية والأفلاممنهافي نوربلاي، نهتم بنشر محتوى محافظ وذو قيمة حيث يتم الاختيار بعناية ليناسب جميع أفرادالعائلة"
          );
        document
          .getElementById("meta-key")
          .setAttribute(
            "content",
            "نوربلاي ، النور، النور تي في، مسلسلات جديدة، مسلسلات تركية، مسلسلات قديمة ، أرطغرول،خدمة المشاهدة أونلاين، ترفيه ، أفلام، برامج للأطفال، كرتون،مشاهدة آمنة، شاهد في أي مكان ةفي أي وقت، حمل الحلقات ،حلقات حصرية من بعد عرضها مباشرة على التلفاز،حلقات مترجمة للعربية والانجليزية، مسلسلات عربية"
          );
        window.document.title =
          "نوربلاي- شاهد محتوى آمن لك ولجميع أفراد عائلتك من مسلسلات حصرية وأفلام وبرامج للأطفال اونلاين";
      } else {
        document
          .getElementById("meta-desc")
          .setAttribute(
            "content",
            "At Noorplay we care about conservative content and family values. Every content is carefully picked and edited to suit all family members.Noor Play is a streaming service that offers a wide variety of TV shows and more"
          );
        document
          .getElementById("meta-key")
          .setAttribute(
            "content",
            "noorplay, alnoor, turkish series, kids, arabic series, the ottoman, Kurulus Osman, series, movies, entertainment"
          );
        window.document.title = "Noor Play - unlimited TV shows and more";
      }
    }
  },
  beforeDestroy() {
    // console.log("what the is full path----", this.$route.fullPath);
    eventBus.$off(`contentDetail-response-${this.contentId}`);
    eventBus.$off("changeLanguageView");

    window.document.title = "Noor Play - unlimited TV shows and more";
    this.actChangeMetaData();
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.errorMessage {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  font-size: 1.4rem;
}
.main-layout {
  display: flex;
  flex-direction: column;
  .content-container {
    display: flex;
    justify-content: space-between;
    .content-detail {
      margin-top: 10px;
      margin-left: 40px;
      margin-right: 40px;
      width: 400px;
      .contentTags {
        display: flex;
        .tag {
          margin-top: 20px;
          background-color: $btn-clr;
          text-align: left;
          font-family: $font-regular;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
          padding: 5px 5px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          font-size: 0.8rem;
        }
      }
      .contentTagsRight {
        display: flex;
        .tag {
          margin-top: 20px;
          background: $btn-clr-new;
          text-align: left;
          font-family: $font-regular;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
          padding: 5px 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          font-size: 0.8rem;
        }
      }
      .content-title {
        margin-top: 5px;
        color: #ffffff;
        opacity: 1;
        font-family: $font-regular;
        font-size: 2rem;
      }
      .go-back {
        display: flex;
        .rotate-img {
          transform: rotate(180deg);
        }
        .back {
          margin: 0px 10px;
          color: #848486;
          font-family: $font-regular;
        }
      }
      .content-sub-detail {
        display: flex;
        width: 30vw;
        justify-content: space-between;
        // flex-wrap:wrap;
        align-items: center;
        font-family: $font-regular;
        letter-spacing: 0px;
        color: #efeff4;
        font-size: 1rem;
        font-style: bold;
        text-transform: uppercase;
        opacity: 0.8;
        margin-top: 12px;

        // p:not(:last-child) {
        //   margin: 0 5px;
        //   padding: 0 0.5rem;
        //   border-left: solid 1px #fff;
        // }

        p {
          margin: 0 5px;
        }

        .pg-rating {
          width: 3.5rem;
          padding: 0.4rem 0.6rem;
          border: 1px solid #ffffff;
          line-height: 14px;
        }
      }
      .season-layout {
        display: flex;
        width: 120px;
        .season-info {
          margin-left: 10px;
          margin-right: 10px;
          letter-spacing: 0px;
          color: #848486;
          font-size: 1rem;
          font-family: $font-regular;
          opacity: 1;
          margin-top: 12px;
        }
      }

      .content-description {
        display: flex;
        width: 400px;
        font-family: $font-regular;
        letter-spacing: 0px;
        line-height: 1.6rem;
        font-size: 1rem;
        color: #848486;
        opacity: 1;
        margin-top: 16px;
        text-align: left;

        &-ara {
          text-align: right !important;
        }
      }
      .read-more {
        letter-spacing: 0px;
        color: #efeff4;
        opacity: 0.8;
        font-size: 0.8rem;
        font-family: $font-regular;
        cursor: pointer;
      }

      .audio-subtitle {
        letter-spacing: 0px;
        opacity: 0.8;
        color: #848486;
        font-size: 1rem;
        font-family: $font-regular;
        margin: 1.5rem 0;

        .label {
          font-weight: bold;
        }

        .audio,
        .subtitle {
          margin: 0.5rem 0;
        }
      }

      .content-quick-actions {
        display: flex;
        justify-content: space-between;
        width: 150px;
        margin-top: 15px;
        color: #848486;
        cursor: pointer;
      }
      .content-actions {
        display: flex;
        width: 330px;
        justify-content: space-between;
        margin-top: 8px;
        .play-button {
          margin-top: 8px;
          width: 150px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid $btn-clr;
          background: none;
          color: $btn-clr;
          opacity: 1;
          outline: none;
          font-size: 0.9rem;
          font-family: $font-regular;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            // color: $clr-dark-gd3;
            // color: $clr-light-gd2; // nammaflix color
            color: $clr-dark-hlt;
            background: $btn-clr;
            // transform: scale(1.05); // nammaflix transform
          }
          &:focus {
            outline: none;
          }
        }
        .watch-trailer-button {
          margin-top: 8px;
          width: 150px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #848486;
          background: none;
          color: #848486;
          cursor: pointer;
          opacity: 1;
          outline: none;
          font-size: 0.9rem;
          font-family: $font-regular;
          font-weight: 600;
          &:hover {
            background: #868688;
            color: #ffffff;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .content-image {
      margin-top: 10px;
      width: 820px;
      margin-right: 40px;
      margin-left: 40px;
      position: relative;

      .poster {
        height: 410px;
        object-fit: contain;
        -webkit-mask-image: radial-gradient(ellipse at top, #5c5c5d, transparent 67%),
          radial-gradient(ellipse at bottom, #5c5c5d, transparent 85%);
        mask-image: radial-gradient(ellipse at top, #5c5c5d, transparent 67%),
          radial-gradient(ellipse at bottom, #5c5c5d, transparent 85%);
      }
    }
  }

  .tabs-section {
    margin-top: 24px;
    .tabs-layout {
      margin-top: 20px;
      padding-bottom: 10px;
      display: flex;
      margin: 0 2.5rem;
      padding-top: 0.6rem;
      border-bottom: 1px solid #32363e;
      .tabs-frame {
        display: flex;
      }
      li {
        opacity: 0.5;
        color: #efeff4;
        font-family: $font-regular;
        cursor: pointer;
        &:nth-child(even) {
          margin: 0 20px;
        }
        &:hover {
          opacity: 1;
          color: #efeff4;
        }
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .active-tab {
      border-bottom: 3px solid $btn-clr;
      opacity: 1 !important;
    }
    .episodes-layout {
      margin-top: 10px;
      margin-left: 40px;
      .episode-frame {
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .main-layout {
    display: flex;
    flex-direction: column;
    .content-container {
      display: flex;
      flex-direction: column-reverse;
      .content-detail {
        margin-left: 20px;
        margin-right: 20px;
        .content-sub-detail {
          p {
            font-size: 0.8rem;
          }
          .pg-rating {
            width: 2rem;
            padding: 0.1rem 0.25rem;
            line-height: 10px;
            font-size: 0.6rem;
          }
          margin-top: 14px;
        }
        .content-title {
          font-size: 1.6rem;
        }
        .content-description {
          display: flex;
          width: 340px;
          font: $font-regular;
          letter-spacing: 0px;
          color: #848486;
          text-transform: capitalize;
          margin-top: 15px;
          text-align: left;
          line-height: 1.6rem;
          font-family: $font-regular;
        }
        .content-sub-detail {
          font-size: 1.2rem;
        }
        .content-quick-actions {
          display: flex;
          justify-content: space-between;
          width: 150px;
          margin-top: 24px;
          color: #848486;
        }
        .content-actions {
          margin-top: 16px;
        }
      }
      .content-image {
        margin-top: 20px;
        width: 340px;
        margin-left: 20px;
        margin-right: 20px;
        .poster {
          max-width: 330px;
          height: auto;
        }
      }
    }

    .tabs-section {
      margin-top: 20px;
      .tabs-layout {
        margin: 0 1.2rem;
        .tabs-frame {
          display: flex;
        }
      }
      .episodes-layout {
        margin-top: 15px;
        margin-left: 20px;
        .episode-frame {
          color: white;
        }
      }
    }
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .main-layout {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    .content-container {
      display: flex;
      flex-direction: column-reverse;
      .content-detail {
        margin-left: 20px;
        .content-description {
          display: flex;
          width: 600px;
          letter-spacing: 0px;
          color: #848486;
          text-transform: capitalize;
          margin-top: 24px;
          text-align: left;
          font-family: $font-regular;
          font-size: 1rem;
        }
        .content-quick-actions {
          display: flex;
          justify-content: space-between;
          width: 150px;
          margin-top: 24px;
          color: #848486;
        }
        .content-actions {
          margin-top: 32px;
        }
      }
      .content-image {
        margin-top: 20px;
        width: 720px;
        margin-left: 20px;
        margin-right: 30px;
        .poster {
          max-width: 700px;
          height: auto;
        }
      }
    }

    .tabs-section {
      margin-top: 20px;
      margin-right: 20px;
      .tabs-layout {
        margin: 0 1.2rem;
        .tabs-frame {
          display: flex;
        }
      }
      .episodes-layout {
        margin-top: 15px;
        margin-left: 20px;
        .episode-frame {
          color: white;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
  .main-layout {
    display: flex;
    flex-direction: column;

    margin-left: 20px;
    .content-container {
      display: flex;

      .content-detail {
        margin-left: 20px;
        width: 280px;
        .content-sub-detail {
          // width: 280px;
          font-size: 0.8rem;

          .pg-rating {
            width: 2.5rem;
            padding: 0.1rem 0.25rem;
          }
        }
        .content-description {
          display: flex;
          width: 300px;
          letter-spacing: 0px;
          color: #848486;
          text-transform: capitalize;
          margin-top: 24px;
          text-align: left;
          font-family: $font-regular;
          font-size: 1rem;
        }
        .content-quick-actions {
          display: flex;
          justify-content: space-between;
          width: 150px;
          margin-top: 24px;
          color: #848486;
        }
        .content-actions {
          margin-top: 20px;
        }
      }
      .content-image {
        margin-top: 10px;
        width: 600px;
        margin-left: 40px;
        margin-right: 40px;
        .poster {
          max-width: 600px;
          height: auto;
        }
      }
    }

    .tabs-section {
      margin-top: 20px;
      margin-right: 20px;
      .tabs-layout {
        margin: 0 1.2rem;
        .tabs-frame {
          display: flex;
        }
      }
      .episodes-layout {
        margin-top: 15px;
        margin-left: 20px;
        .episode-frame {
          color: white;
        }
      }
    }
  }
}

@media (min-width: 1300px) {
  .main-layout {
    .content-container {
      // .content-detail {
      // }
      .content-image {
        width: 890px;
        .poster {
          max-width: 890px;
          height: auto;
        }
      }
    }
  }
}
.active-pointer {
  cursor: pointer;
}
</style>
